<template>
  <div id="category-management-detail">
    <el-button
      v-if="fullAccess"
      class="button black action-button with-icon icon-spaced"
      @click="addSubCategory"
    >
      <span>
        <i class="custom-icon icon-add mini"></i>
        <span>{{ $t("ADD_SUB_CATEGORY") }}</span>
      </span>
    </el-button>

    <el-tabs tab-position="top" class="detail-language-options" type="card" stretch v-model="forLanguage">

      <el-tab-pane 
        v-for="el in getLangList()" 
        :key="el.code" 
        :name="el.code"
      >
        <el-row type="flex" align="middle" slot="label">
          <img class="custom-icon icon-lang mini" :src="getLangFlagImage(el.code)"/>
          <template 
            v-if="!isMobile || el.code === 'hi' || el.code === 'ta'"
          >{{ el.label }}</template>
        </el-row>
      </el-tab-pane>
      
      <el-form 
        ref="form" 
        :model="detail" 
        :rules="rules"
        label-position="top" 
        label-width="120px"
        :disabled="!fullAccess"
        @submit.native.prevent="handleSubmit"
        @keydown.native="preventEnterKey"
      >
        <el-row v-if="!isAdding" type="flex" justify="end" align="middle" class="status-row">
          <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
          <el-select 
            v-model="detail.status"
            :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')">
            <el-option
              v-for="item in allSelectableStatusListFor(detail.status)"
              :key="item.value"
              :label="$t(item.value)"
              :value="item.value"
            />
          </el-select>
        </el-row>

        <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">

          <el-col>
            <el-form-item class="is-required" :label="$t('CATEGORY_NAME')" prop="detailName">
              <el-input 
                v-model="detailName"
                :placeholder="$t('ENTER_MAIN_CATEGORY')"
                @blur="(e) => trimCategoryValue(e)"
              >
                <template slot="prefix">
                  <i class="custom-icon icon-category mini"/>
                </template>
              </el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row v-for="(secondCat, secondCatIndex) in this.detail.childCategory" :key="secondCat.id">
          <el-row>
            <el-form-item
              class="is-required"
              :label="`${$t('SUB_CATEGORY_NAME')} ${secondCatIndex + 1}`"
              :prop="'childCategory.' + secondCatIndex"
            >
              <el-input
                class="has-remove-btn"
                :placeholder="$t('ENTER_SECOND_LEVEL_CATEGORY')"
                :value="getNameFromTransList(secondCat, '', onlyLanguage || forLanguage)"
                @input="(val) => setSecondCategory(val, secondCatIndex)"
                @blur="(e) => trimCategoryValue(e, secondCatIndex)"
              >
                <template slot="prefix">
                  <i class="custom-icon icon-category mini"></i>
                </template>
                <template slot="suffix">
                  <span
                    v-if="fullAccess"
                    :class="`remove-sub-cat-btn ${isRemoveSubCategoryButtonDisabled(secondCat) && 'disabled'}`"
                    @click="removeSubCategory(secondCatIndex, isRemoveSubCategoryButtonDisabled(secondCat))"
                  >
                    {{ $t('REMOVE_SUB_CATEGORY') }}
                  </span>
                </template>
              </el-input>
            </el-form-item>
          </el-row>

          <el-row 
            justify="space-between"
            align="middle"
            :gutter="16"
          >
            <template v-if="secondCat.childCategory && secondCat.childCategory.length > 0">
              <el-col v-for="(thirdCat, thirdCatIndex) in secondCat.childCategory" :key="thirdCat.id" :span="8">
                <el-form-item :prop="'childCategory.' + secondCatIndex + '.childCategory.' + thirdCatIndex">
                  <el-input
                    :placeholder="`${$t('ENTER_THIRD_LEVEL_CATEGORY')} ${thirdCatIndex + 1} (${$t('IF_ANY')})`"
                    :value="getNameFromTransList(thirdCat, '', onlyLanguage || forLanguage)"
                    @input="(val) => setThirdCategory(val, secondCatIndex, thirdCatIndex)"
                    @blur="(e) => trimCategoryValue(e, secondCatIndex, thirdCatIndex)"
                    :ref="`thirdCategoryInput-${secondCatIndex}-${thirdCatIndex}`"
                  >
                    <template slot="suffix">
                      <i 
                        v-if="fullAccess"
                        :class="`custom-icon icon-cross cross-size pointer ${thirdCat.isLinkedProduct && 'disabled'}`" 
                        @click="removeThirdCategory(secondCatIndex, thirdCatIndex, thirdCat.isLinkedProduct)"
                      ></i>
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-input
                  class="empty-input"
                  :placeholder="`${$t('ENTER_THIRD_LEVEL_CATEGORY')} ${secondCat.childCategory.length + 1} (${$t('IF_ANY')})`"
                  @input="(val) => addThirdCategory(val, secondCatIndex, secondCat.childCategory.length)"
                ></el-input>
              </el-col>
            </template>
            <el-col :span="8" v-else>
              <el-input
                class="empty-input"
                :placeholder="`${$t('ENTER_THIRD_LEVEL_CATEGORY')} 1 (${$t('IF_ANY')})`"
                @input="(val) => addThirdCategory(val, secondCatIndex, 0)"
              ></el-input>
            </el-col>
          </el-row>
        </el-row>

        <page-footer
          :isLoading="isLoading"
          :handleBackTo="exit"
          :handleSubmit="fullAccess ? () => {} : null"
        />

      </el-form>

    </el-tabs>
  </div>
</template>


<script>
import { genericRecordsDetailMixin } from '@/utils/_mixins/generic-records-detail'
import { generalMixin } from "@/utils/general-mixin";
import { EventBus } from "@/utils/event-bus.js"
import PageFooter from "@/components/page-footer.vue"
import { mapGetters } from "vuex";

export default {
  name: 'CategoryManagementDetail',
  components: { PageFooter },
  mixins: [ genericRecordsDetailMixin, generalMixin ],
  data() {
    return {
      type: 'category',
      rules: {
        detailName: [
          { validator: this.checkName, trigger: 'blur' },
        ]
      },
    }
  },
  watch: {
    'detail.childCategory': {
      handler(newSecondCategory) {
        this.updateValidationRules(newSecondCategory);
      },
      deep: true,
    },
    'forLanguage': {
      handler() {
        if (this.detail.translationList && this.detail.translationList.some((translationObj) => translationObj.name !== "")) {
          this.$refs.form.validateField('detailName');
        }

        if (this.detail && this.detail.childCategory) {
          this.updateValidationRules(this.detail.childCategory);
        }
      },
    },
  },
  computed: {
    ...mapGetters("app", ["getLangList", "getLangFlagImage"]),
  },
  methods: {
    // REDIRECTION
    exit() { 
      this.goTo('/bo/category')
      EventBus.$emit("refresh-category")
    },
    checkURL(_, value, callback) {
      if (!value || value == "") {
        callback(new Error(this.$t("URL_CANNOT_EMPTY")));
      } else {
        callback()
      }
    },
    trimCategoryValue(event, secondCatIndex, thirdCatIndex) {
      if (!event.target || typeof event.target.value !== "string") return;
      const trimmedValue = event.target.value.trim();

      if (secondCatIndex >= 0 && thirdCatIndex >= 0) {
        this.setThirdCategory(trimmedValue, secondCatIndex, thirdCatIndex);
      } else if (secondCatIndex >= 0 && thirdCatIndex === undefined) {
        this.setSecondCategory(trimmedValue, secondCatIndex);
      } else if (secondCatIndex === undefined && thirdCatIndex === undefined) {
        this.detailName = trimmedValue;
      }
    },
    updateValidationRules(secondCategory) {
      if (secondCategory && secondCategory.length > 0) {
        secondCategory.forEach((secondCat, secondCatIndex) => {
          this.$set(this.rules, `childCategory.${secondCatIndex}`, [
            {
              validator: this.checkSecondCategoryName,
              trigger: 'blur',
            },
          ]);

          this.$nextTick(() => {
            this.$refs.form.validateField(`childCategory.${secondCatIndex}`);
          });

          if (secondCat.childCategory && secondCat.childCategory.length > 0) {
            secondCat.childCategory.forEach((_, thirdCatIndex) => {
              this.$set(this.rules, `childCategory.${secondCatIndex}.childCategory.${thirdCatIndex}`, [
                {
                  validator: this.checkThirdCategoryName,
                  trigger: 'blur'
                },
              ]);

              this.$nextTick(() => {
                this.$refs.form.validateField(`childCategory.${secondCatIndex}.childCategory.${thirdCatIndex}`);
              });
            });
          }
        });
      }
    },
    checkSecondCategoryName(rule, _, callback) {
      const secondCatIndex = rule.field.split('.')[1];
      const name = this.getNameFromTransList(this.detail.childCategory[secondCatIndex], '', this.onlyLanguage || this.forLanguage)
      const secondCatNameEn = this.getNameFromTransList(this.detail.childCategory[secondCatIndex], "", "en");

      if (!name || name.trim().length <= 0) {
        callback(new Error(this.$t("NAME_CANNOT_EMPTY")));
      } else if (!secondCatNameEn || secondCatNameEn.trim().length <= 0) {
        callback(new Error(this.$t("EN_NAME_CANNOT_EMPTY")));
      } else {
        callback();
      }
    },
    checkThirdCategoryName(rule, _, callback) {
      const secondCatIndex = rule.field.split('.')[1];
      const thirdCatIndex = rule.field.split('.')[3];
      const name = this.getNameFromTransList(this.detail.childCategory[secondCatIndex].childCategory[thirdCatIndex], '', this.onlyLanguage || this.forLanguage)
      const thirdCatNameEn = this.getNameFromTransList(this.detail.childCategory[secondCatIndex].childCategory[thirdCatIndex], "", "en");

      if (!name || name.trim().length <= 0) {
        callback(new Error(this.$t("NAME_CANNOT_EMPTY")));
      } else if (!thirdCatNameEn || thirdCatNameEn.trim().length <= 0) {
        callback(new Error(this.$t("EN_NAME_CANNOT_EMPTY")));
      } else {
        callback();
      }
    },
    addSubCategory() {
      if (!this.detail.childCategory) {
        this.$set(this.detail, "childCategory", []);
      }

      this.detail.childCategory.push({
        childCategory: []
      });
    },
    removeSubCategory(secondCatIndex, isLinked) {
      if (isLinked) return;

      const confirmationMessage = `${this.$t("REMOVE_SUB_CATEGORY_CONFIRMATION")} ${secondCatIndex + 1}?`;
      const title = this.$t("ARE_YOU_SURE");

      this.$confirm(confirmationMessage, title)
        .then(() => {
          this.detail.childCategory.splice(secondCatIndex, 1);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isRemoveSubCategoryButtonDisabled(secondCat) {
      return secondCat.isLinkedProduct || (secondCat.childCategory && secondCat.childCategory.some(thirdCat => thirdCat.isLinkedProduct));
    },
    addThirdCategory(value, secondCatIndex, thirdCatIndex) {
      const secondCategory = this.detail.childCategory[secondCatIndex];

      if (!secondCategory.childCategory) {
        this.$set(secondCategory, "childCategory", []);
      }

      if (value.trim() !== "") {
        secondCategory.childCategory.push({
          translationList: [
            {
              name: value,
              language: this.langList.find(
                (el) => el.code === (this.onlyLanguage || this.forLanguage)
              ),
            },
          ],
        });

        this.setThirdCategory(value, secondCatIndex, thirdCatIndex);

        this.$nextTick(() => {
          const newThirdCategoryInputRef = this.$refs[`thirdCategoryInput-${secondCatIndex}-${thirdCatIndex}`];
          if (newThirdCategoryInputRef && newThirdCategoryInputRef.length > 0) {
            newThirdCategoryInputRef[0].focus();
          }
        })
      }
    },
    removeThirdCategory(secondCatIndex, thirdCatIndex, isLinked) {
      if (isLinked) return;

      const confirmationMessage = `${this.$t("REMOVE_THIRD_CATEGORY_CONFIRMATION")} ${thirdCatIndex + 1}?`;
      const title = this.$t("ARE_YOU_SURE");

      this.$confirm(confirmationMessage, title)
        .then(() => {
          this.detail.childCategory[secondCatIndex].childCategory.splice(thirdCatIndex, 1);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setSecondCategory(newValue, secondCatIndex) {
      const locale = this.onlyLanguage || this.forLanguage;

      if (this.detail && this.detail.childCategory && this.detail.childCategory[secondCatIndex]) {
        let secondCategory = this.detail.childCategory[secondCatIndex];

        if (!secondCategory.translationList) {
          this.$set(secondCategory, "translationList", []);
        }
        if (!secondCategory.type) {
          this.$set(secondCategory, 'type', 'category');
        }

        let translationIndex = secondCategory.translationList.findIndex(el => el.language.code === locale);
        let translationObj = secondCategory.translationList[translationIndex];

        if (!translationObj) {
          translationObj = {
            name: newValue,
            language: this.langList.find(el => el.code === locale)
          };
          translationIndex = secondCategory.translationList.length;
        } else {
          translationObj.name = newValue;
        }

        this.$set(secondCategory.translationList, translationIndex, translationObj);
      }
    },
    setThirdCategory(newValue, secondCatIndex, thirdCatIndex) {
      const locale = this.onlyLanguage || this.forLanguage;

      if (this.detail && this.detail.childCategory && this.detail.childCategory[secondCatIndex] && this.detail.childCategory[secondCatIndex].childCategory[thirdCatIndex]) {
        let thirdCategory = this.detail.childCategory[secondCatIndex].childCategory[thirdCatIndex];

        if (!thirdCategory.translationList) {
          this.$set(thirdCategory, 'translationList', []);
        }
        if (!thirdCategory.type) {
          this.$set(thirdCategory, 'type', 'category');
        }

        let translationIndex = thirdCategory.translationList.findIndex(el => el.language.code === locale);
        let translationObj = thirdCategory.translationList[translationIndex];

        if (!translationObj) {
          translationObj = {
            name: newValue,
            language: this.langList.find(el => el.code === locale)
          };
          translationIndex = thirdCategory.translationList.length;
        } else {
          translationObj.name = newValue;
        }

        this.$set(thirdCategory.translationList, translationIndex, translationObj);
      }
    },
    preventEnterKey(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
  },
  mounted() {
    this.isCategory = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";

#category-management-detail {
  @include detail-language-options;
  
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative;
  .status-row {
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
  .icon-lang {
    margin-right: 5px;
  }

  .action-button {
    z-index: 2;
    position: absolute;
    top: -4rem;
    right: 0;
    
    &:hover {
      .custom-icon {
        @extend .black-icon;
      }
    }
  }

  .el-input__prefix {
    left: 0.625rem;
  }

  .el-input--prefix .el-input__inner {
    padding-left: 2.188rem;
  }

  .has-remove-btn .el-input__inner {
    padding-right: 8.75rem !important;
  }

  .el-input__suffix {
    right: 0.625rem;

    &:has(.custom-icon) {
      top: 0.188rem;
      
      .cross-size {
        height: 0.813rem;
        width: 0.813rem;
        background-size: 0.813rem 0.813rem;
      }
    }
  }

  .remove-sub-cat-btn {
    font: normal normal normal 0.813rem SourceSansPro;
    color: #FF5B1C;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .disabled:hover {
    cursor: not-allowed;
  }

  .empty-input {
    margin-bottom: 1.375rem;
  }
}

.mobile {
  #category-management-detail {
    @include detail-language-options-mobile;
  }
}
</style>